<template>
  <div>
    <h3>Property Feedback</h3>
    <div class="card" v-if="!loading">
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Property</th>
              <th>Message</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="feedback.length == 0">
              <td colspan="4">There isno data to display</td>
            </tr>
            <tr v-for="(msg, i) in feedback" :key="`feedback-${i}`">
              <td>{{ msg.name }}</td>
              <td>{{ msg.content }}</td>
              <td>{{ msg.created_at }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'admin.properties.show',
                    params: { id: msg.id },
                  }"
                  >Details</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get('/api/v1/admin/property-feedback').then(response => {
        this.feedback = response.data.feedback
        this.loading = false
      })
    }
  }
}
</script>